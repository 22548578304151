<template>
  <main class="product-level1-page page-new-product">
    <BackHeader>
      <template v-slot:center>
        <h2 class="text-grey-lg title-header-category">
          {{ state.title }}
        </h2>
      </template>
      <template v-slot:right>
        <div class="back-header-right-side">
          <router-link
            :to="{ name: 'search' }"
            class="search-icon dosiin_search-header-btn"
          >
            <i class="dsi dsi-search"></i>
          </router-link>
        </div>
      </template>
    </BackHeader>

    <div class="product-page-header_wrap">
      <h1 class="title" v-text="state.title"></h1>
      <h2 class="subtitle" v-text="state.des"></h2>
    </div>
    <div class="page-nav-bar-sticky">
      <div
        class="page-nav-bar dosiin_page-nav-bar dosiin_filter-result-nav-bar filter-result-nav-bar"
      >
        <div class="total-result" style="display: flex">
          <b class="total-product" v-text="numberFormat(state.totalProducts)"></b>
          &nbsp;sản phẩm
        </div>
      </div>
    </div>
    <div class="page-section">
      <ProductLoaderApiData
        :type="type"
        :query="params"
        gridClass="grid-50"
        @total-products="updateTotals"
      />
    </div>

    <Intersection @onIntersection="onIntersectionProductFavorite()">
      <ProductsSliderHorizon
        wrapperClass="page-section"
        title="Sản phẩm bán chạy"
        gridClass="grid-33"
        :products="state.productsBestSelling"
        :options="{
          slidesPerView: 2.5,
          slidesPerGroup: 2,
          spaceBetween: 9,
        }"
      />
    </Intersection>
  </main>
</template>

<script>
import { reactive, inject } from "vue";
import BackHeader from "@/components/common/BackHeader";
import ProductLoaderApiData from "@/components/product/ProductLoaderApiData";
import ProductsSliderHorizon from "@/components/sliders/ProductsHorizon";
import DataService from "@/services/dosiin";
import { serialize } from "@/utlis/serialize";

export default {
  components: {
    BackHeader,
    ProductLoaderApiData,
    ProductsSliderHorizon,
  },
  setup() {
    const type = inject("type");
    const params = inject("params");
    const state = reactive({
      title: "Sản phẩm yêu thích",
      des: "Các sản phẩm yêu thích nhất ở Dosi-in",
      totalProducts: 0,
      productsBestSelling: [],
    });

    async function onIntersectionProductFavorite() {
      const response = await DataService.fetchData(
        serialize({ type: "best_like", limit: 21 })
      );
      if (response.data) {
        state.productsBestSelling = Object.values(response.data.products);
      }
    }

    const updateTotals = (total) => {
      state.totalProducts = total;
    };

    return {
      state,
      type,
      params,
      updateTotals,
      onIntersectionProductFavorite,
    };
  },
};
</script>
