<template>
    <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">
        <div class="dosiin_wrapper-bar dosiin_mb-16">
            <Breadcrumbs
                :breadcumbs="breadcumbs"
            />
            <div class="dosiin-tab-bar-pc dosiin_mb-16">
                <div class="tab-bar-pc-content">
                    <div class="title-page d-flex align-items-center justify-content-between">
                        <div class="title-all-categories">
                            {{ title }}
                        </div>
                        <div class="tab-bar-content-quantity">
                            <span class="quantity" v-text="numberFormat(totalProducts)"></span>
                            <span class="text-quantity">Sản phẩm tìm thấy</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-section">
            <ProductLoaderApiData 
                :type="type"
                :query="params"
                @total-products="updateTotals"
            />
        </div>

    </div>
</template>

<script>
    import ProductLoaderApiData from '@/components/product/ProductLoaderApiData';
    import { ref , inject } from 'vue';

    export default {
        components:{
            ProductLoaderApiData
        },
        setup() {
            const title  = 'Sản phẩm yêu thích';
            const totalProducts = ref(0);
            const type = inject('type');
            const params = inject('params');
            const breadcumbs = [
                {'title' : title , 'route' : '#'}
            ];

            
            const updateTotals = (total) => {
                totalProducts.value = total;
            }

            return {
                title,
                breadcumbs,
                totalProducts,
                type,
                params,
                updateTotals
            }
        },
    }
</script> 