<template>
    <ProductsFavoriteMB v-if="layout == 4 " />
    <ProductsFavoritePC v-else />
</template>

<script>
    import ProductsFavoriteMB from '@/pages/mb/ProductsFavorite';
    import ProductsFavoritePC from '@/pages/pc/ProductsFavorite';
    import { provide, reactive } from 'vue';
    import { useRoute } from 'vue-router';

    export default {
        components:{
            ProductsFavoriteMB,
            ProductsFavoritePC
        },
        setup() {
            const route = useRoute();
            const type = 'best_like';
            const params = reactive({
                page : route.query.page ? route.query.page : 1,
                type_category : route.query.type_category ? route.query.type_category : '',
                type
            });

            provide('type' , type);
            provide('params' , params);
        },
    }
</script>