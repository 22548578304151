<template>
  <InfiniteLoad
    @infinite-scroll="loadMore"
    :isLoading="state.loading"
    :noResult="state.endLoad"
  >
    <div class="section-title slide-view-container" v-if="changeGrid">
      <Slider
        v-model="gridLayout"
        class="view-container"
        :min="0"
        :max="2"
        :step="-1"
        :tooltips="false"
      />
    </div>

    <div>
      <ProductLoading :wrapperClass="'grid ' + gridClass" v-if="state.skeleton" />

      <div v-else-if="products.length > 0" class="grid" :class="gridClass">
        <div class="grid-item" v-for="(product, i) in products" :key="i">
          <ProductItem :product="product" :showRating="true" />
        </div>
      </div>

      <EmptyComponent v-else :title="true" />
    </div>
  </InfiniteLoad>
</template>

<script>
import { ref, watch, reactive } from "vue";
import DataService from "@/services/dosiin";
import ProductItem from "@/components/product/ProductItem";
import Pagination from "@/components/pagination/Pagination";
import ProductLoading from "@/components/skeletons/Products";
import InfiniteLoad from "@/components/common/InfiniteScroll";

import { serialize } from "@/utlis/serialize";
import { _dsEmptyObject } from "@/utlis/helpers";
import { productGridClass } from "@/utlis/global";
import Slider from "@vueform/slider";

export default {
  components: {
    ProductLoading,
    ProductItem,
    Pagination,
    Slider,
    InfiniteLoad,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    query: {
      type: Object,
      require: true,
    },
    gridClass: {
      type: String,
      default: "grid-20",
    },
    changeGrid: {
      type: Boolean,
      default: false,
    },
    grid: {
      type: Number,
      default: 3,
    },
  },
  emits: ["totalProducts"],
  setup(props, { emit }) {
    const state = reactive({
      init: false,
      loading: true,
      page: 1,
      totalPage: 0,
      endLoad: false,
      skeleton: true,
    });

    const pagination = ref({});
    const products = ref([]);
    const gridLayout = ref(props.grid);
    const gridClass = ref(props.gridClass);
    getProducts({ init: true });
    async function getProducts({ init }) {
      state.loading = true;
      const queryParams = props.query;

      if (_dsEmptyObject(queryParams)) return;

      if (init) {
        var query_string = serialize(queryParams);
      } else {
        // pagination.value.page = state.page;
        var query_string = serialize({ type: props.type, page: state.page });
        queryParams.page = state.page;
      }

      const response = await DataService.fetchData(query_string);

      products.value.push(...response.data.products);
      pagination.value = response.data.params;
      state.totalPage = Math.ceil(
        pagination.value.total_items / pagination.value.items_per_page
      );
      state.page = pagination.value.page;
      state.loading = false;
      state.skeleton = false;
      state.init = true;
      emit("totalProducts", pagination.value.total_items);
    }

    watch(
      () => gridLayout.value,
      (gridId) => {
        if (gridId < 0.5) gridId = 0;
        if (gridId > 0.5 && gridId < 1) gridId = 1;
        if (gridId > 1 && gridId < 1.5) gridId = 1;
        if (gridId > 1.5 && gridId <= 2) gridId = 2;

        const gridSelected = productGridClass[gridId];
        if (gridSelected) gridClass.value = gridSelected;
      }
    );
    const loadMore = () => {
      if (state.endLoad || !state.init || state.loading) return;
      state.page++;
      getProducts({ init: false });
    };

    return {
      state,
      pagination,
      products,
      gridLayout,
      gridClass,
      //methods
      loadMore,
      getProducts,
    };
  },
};
</script>

<style>
.grid.grid-50 .grid-item,
.grid.grid-33 .grid-item,
.grid.grid-11 .grid-item {
  -webkit-transition: width 0.3s ease-in-out;
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
</style>

<style src="@vueform/slider/themes/default.css"></style>
